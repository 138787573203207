// import * as React from "react"
// import {Link} from 'gatsby'
// import Layout from "../components/newLayout"

// markup
// const IndexPage = () => {
//     return (
//         <Layout isHomePage>
//             <div id="hero-homepage" class="hero" style={{backgroundImage: "url('/img/Hero-Image-4-BBall-A.jpg')", backgroundPositionY: '30%' }}>
//                 <div class="hero-container">
//                     <div class="hero-div">
//                         <h1>Your path to recovery</h1>
//                         <p>Each person with an injury, can access, understand and own their path to their recovery.</p>
//                     </div>
//                 </div>
//             </div>
//             <div class="section link-blurb-section">
//                 <div class="section-inner">
//                     <div class="link-blurb-wrap">
//                         <Link to="/about">
//                             <div class="link-blurb">
//                                 <img src="/img/Icon-Resources.svg" class="link-blurb-img" />
//                                     <div class="link-blurb-info">
//                                         <a href="#" class="link-blurb-title">About Careway <span class="icon arrow-right"></span></a>
//                                     </div>
//                             </div>
//                         </Link>
//                         </div>
//                         <div class="link-blurb-wrap">
//                             <div class="link-blurb">
//                                 <img src="/img/Icon-Consent-Form.svg" class="link-blurb-img" />
//                                     <div class="link-blurb-info">
//                                         <a href="#" class="link-blurb-title">Consent Form <span class="icon arrow-right"></span></a>
//                                     </div>
//             </div>
//                             </div>
//                             <div class="link-blurb-wrap">
//                                 <div class="link-blurb">
//                                     <img src="/img/Icon-Find-Provider.svg" class="link-blurb-img" />
//                                         <div class="link-blurb-info">
//                                             <a href="#" class="link-blurb-title">Find A Provider <span class="icon arrow-right"></span></a>
//                                         </div>
//             </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div class="section intro-section">
//                             <div class="section-inner">
//                                 <div class="fullwidth-blurb">
//                                     <h2>Coordinated Healthcare Services</h2>
//                                     <p>Careway is an ACC funded programme that aims to unify treatment stages, providing an individualised <span class="highlight">free treatment plan</span> with a faster timeframe to recovery.</p>
//                                 </div>
//                             </div>
//                         </div>
//                         <div class="section">
//                             <div class="section-inner info-blurbs">
//                                 <div class="info-blurb">
//                                     <img src="/img/Icon-Knee.svg" />
//                                         <h3>Knee Injuries</h3>
//                                         <p>Ped eatur re sitatincto dolumqu iandenihit, quature rnatemo dignam qui veliqui verferfero con nis porrum qui di aut quo et ducita verro beate omnimpo</p>
//                                         <div class="info-blurb-links">
//                                             <a class="cta-sec">ACL</a>
//                                             <a class="cta-sec">MENISCAL</a>
//                                         </div>
//             </div>
//                                     <div class="info-blurb">
//                                         <img src="/img/Icon-Shoulder.svg" />
//                                             <h3>Shoulder Injuries</h3>
//                                             <p>Officienim aliquam ent aut voluptati quia dolut re, volupta dero es dicitat. Nam, con pratiore cone porrovid quo</p>
//                                             <div class="info-blurb-links">
//                                                 <a class="cta-sec">READ MORE</a>
//                                             </div>
//             </div>
//                                         <div class="info-blurb">
//                                             <img src="/img/Icon-Spine.svg" />
//                                                 <h3>Spine Injuries</h3>
//                                                 <p>Aximendant doloren imporum quid ulpa sin et apientis et voles ea ipsum lic te sintem qui doloribus, ipictot atemped magnias aliam la ipsunt maiosse quibusd</p>
//                                                 <div class="info-blurb-links">
//                                                     <a class="cta-sec">READ MORE</a>
//                                                 </div>
//             </div>
//                                         </div>
//                                     </div>
//         </Layout>
//     )
// }

// export default IndexPage

export default function NotFound() {
    if (typeof window !== 'undefined') {
        window.location = '/';
    }

    return null;
}

